<template>
  <v-container fluid>
    <!-- Encadre titre de la page -->
    <v-toolbar
      color="transparent"
      :dark="this.$store.state.darkTheme ? true : false"
    >
      <v-toolbar-title>
        <h1 v-if="!isMobile()" class="font-weight-light">Project database</h1>
        <h2 v-if="isMobile()" class="font-weight-light">Project database</h2>
      </v-toolbar-title>
    </v-toolbar>

    <!-- Quelque chiffres sur les comptes Perseus -->
    <v-row class="mt-4">
      <v-col align="center" class="mx-6">  
        <v-row justify="space-around">
        <v-avatar
          color="primary"
          size="100"
        >
          <h1>{{nb_active}}</h1>
        </v-avatar>
        </v-row>
        <v-row justify="space-around">
          <h2 class="title">Actives</h2>
        </v-row>
      </v-col>
      <v-col align="center" class="mx-6">  
        <v-row justify="space-around">
        <v-avatar
          color="primary"
          size="100"
        >
          <h1>{{nb_pending}}</h1>
        </v-avatar>
        </v-row>
        <v-row justify="space-around">
          <h2 class="title">Pending</h2>
        </v-row>
      </v-col>
      <v-col align="center" class="mx-6">  
        <v-row justify="space-around">
        <v-avatar
          color="primary"
          size="100"
        >
          <h1>{{nb_expired}}</h1>
        </v-avatar>
        </v-row>
        <v-row justify="space-around">
          <h2 class="title">Expired</h2>
        </v-row>
      </v-col>
      <v-col align="center" class="mx-6">  
        <v-row justify="space-around">
        <v-avatar
          color="primary"
          size="100"
        >
          <h1>{{nb_rejected}}</h1>
        </v-avatar>
        </v-row>
        <v-row justify="space-around">
          <h2 class="title">Rejected</h2>
        </v-row>
      </v-col>
    </v-row>

    <!-- Barre de recherche -->
    <v-row class="mx-1">
      <v-col>
        <v-row align="center">
          <v-col>
            <v-row>
              <!-- Filtre par UID de projet -->
              <v-text-field
                v-model.lazy="projectSearch"
                label="Project uid"
                :dark="this.$store.state.darkTheme ? true : false"
                class="mx-4"
                @keyup.enter="search"
              />
              <!-- Filtre par status de projet -->
              <v-select
                v-model.lazy="StatusSearch"
                :items="StatusList"
                label="Project status"
                class="mx-4"
                :dark="this.$store.state.darkTheme ? true : false"
                clearable
              />
              <!-- Filtre par nom de Pole -->
              <v-select
                v-model.lazy="poleSearch"
                :dark="this.$store.state.darkTheme ? true : false"
                :items="this.$store.state.listAllPoles"
                label="Pole Name"
                class="mx-4"
                clearable
              />
              <!-- Filtre par nom de Laboratoire -->
              <v-select
                v-model.lazy="labSearch"
                :dark="this.$store.state.darkTheme ? true : false"
                :items="this.$store.state.listAllLabs"
                label="Lab Name"
                class="mx-4"
                clearable
              />
              <!-- Filtre par UID d'utilisateur membre de projet -->
              <v-text-field
                v-model.lazy="memberSearch"
                label="Member uid"
                :dark="this.$store.state.darkTheme ? true : false"
                class="mx-4"
                @keyup.enter="search"
              />
              <!-- Filtre par UID d'utilisateur admin de projet -->
              <v-text-field
                v-model.lazy="adminSearch"
                label="Admin uid"
                :dark="this.$store.state.darkTheme ? true : false"
                class="mx-4"
                @keyup.enter="search"
              />
            </v-row>
          </v-col>
          <v-col
            v-if="!isMobile()"
            cols="1"
            class="text-right"
            align-self="end"
          >
            <v-btn color="primary" class="my-6" fab @click="search">
              <v-icon large>mdi-magnify</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-row v-if="isMobile()" class="mx-1">
          <v-btn
            block
            color="primary"
            :dark="this.$store.state.darkTheme ? true : false"
            @click="search"
          >
            Search &thinsp;
            <v-icon large>mdi-magnify</v-icon>
          </v-btn>
        </v-row>

        <v-divider :dark="this.$store.state.darkTheme ? true : false" />
      </v-col>
    </v-row>

    <!-- Resultat -->
    <v-row class="mx-2">
      <!-- Liste des projets -->
      <v-col :cols="isMobile()?12:4">
        <v-card
          class="overflow-y-auto"
          :color="this.$store.state.darkTheme ? card_bg_dark : card_bg_light"
          :max-height="this.$store.state.windowH * (2 / 4)"
          :dark="this.$store.state.darkTheme ? true : false"
        >
        <!--
          <v-card-title v-if="showPending" class="text-muted">
            List of pending projects
          </v-card-title>
          <v-card-title v-if="!showPending" class="text-muted">
            Number of results : {{ projectlist.length }}
          </v-card-title>
        -->

          <v-card-title class="text-muted">
            <span v-if="showPending"> List of {{ projectlist.length }} pending project(s) </span>
            <span v-else> Number of results : {{ projectlist.length }} </span>
            <v-spacer />
            <v-btn
              icon
              color="primary"
              @click="copyText(projectlist)"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-content-copy
                  </v-icon>
                </template>
                <span>Copy list</span>
              </v-tooltip>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <li v-for="(project, key) in projectlist" :key="key">
              <v-btn
                style="text-transform: none"
                :dark="$store.state.darkTheme ? true : false"
                text
                @click="getProject(project)"
              >
                {{ project }}
              </v-btn>
            </li>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Details sur le projet selectionne -->
      <v-col :cols="isMobile()?12:8">
        <v-card
          class="overflow-y-auto"
          :color="this.$store.state.darkTheme ? card_bg_dark : card_bg_light"
          :max-height="this.$store.state.windowH * (3 / 4)"
          :dark="this.$store.state.darkTheme ? true : false"
        >
          <v-card-title>Project information</v-card-title>
          <v-card-text>
            About project {{ project.cn }}:
            <v-simple-table
              style="background-color: transparent"
              dense
              class="text-muted"
            >
              <tr>
                <th style="width: 160px">Name:</th>
                <td>{{ project.cn.slice(3) }}</td>
              </tr>
              <tr>
                <th style="width: 150px">Description:</th>
                <td>{{ project.description }}</td>
              </tr>
              <tr>
                <th>Status:</th>
                <td>{{ project.status }}</td>
              </tr>
              <tr>
                <th>Laboratory:</th>
                <td>{{ project.laboratory.slice(2) }}</td>
              </tr>
              <tr>
                <th>Pole:</th>
                <td>{{ project.pole.slice(2) }}</td>
              </tr>
              <tr>
                <th>Creation date:</th>
                <td>{{ project.beginDate }}</td>
              </tr>
              <tr>
                <th>Validity date:</th>
                <td>{{ project.endDate }}</td>
              </tr>
              <tr>
                <th>Review date:</th>
                <td>{{ project.reviewDate }}</td>
              </tr>
              <tr>
                <th>Scientific review :</th>
                <td>{{ project.scientificReview }}</td>
              </tr>
              <tr>
                <th>Technical review:</th>
                <td>{{ project.technicalReview }}</td>
              </tr>
              <tr>
                <th>Project Type :</th>
                <td>{{ project.projectType }}</td>
              </tr>
            </v-simple-table>
          </v-card-text>
          <v-card-text>
            List of admins:
            <li
              v-for="(admin, key) in project.list_admins"
              :key="key"
              class="mx-4"
            >
              {{ admin }}
            </li>
          </v-card-text>
          <v-card-text>
            List of members:
            <li
              v-for="(member, key) in project.list_members"
              :key="key"
              class="mx-4"
            >
              {{ member }}
            </li>
          </v-card-text>

          <v-card-title>
            Admin actions on project &thinsp;
            <v-icon>mdi-database-edit</v-icon>
          </v-card-title>
          <v-card-text>
            <v-btn v-if="activate" color="gred" @click="activateProject">
              Activate
            </v-btn>
            <v-btn
              v-if="project.cn"
              class="mx-2 my-2"
              color="gred"
              @click="enddateDialog = true"
            >
              <v-icon>mdi-update</v-icon>
              &thinsp; Update end date
            </v-btn>
            <v-btn
              v-if="project.cn"
              icon
              absolute
              top
              right
              x-large
              color="primary"
              :to="`/my-projects/project/${project.cn}`"
            >
              <v-icon>fas fa-search-plus</v-icon>
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <br />

    <!-- Definition des boites de dialogue de la page -->
    <!-- Boite de dialogue : reponse du serveur -->
    <v-dialog
      v-model="reponseDialog"
      persistent
      width="500"
      @keyup.esc="dismiss"
    >
      <v-card>
        <v-card-title>
          Server answer
          <v-spacer />
          <v-btn color="primary" icon @click="dismiss">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          {{ reponse }}
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Boite de dialogue : Modificateur de date de fin -->
    <v-dialog v-model="enddateDialog" width="500">
      <v-card>
        <v-card-title class="headline primary">
          Modify end date for {{ project.cn }}
        </v-card-title>
        <v-card-text>
          <v-container>
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="enddate"
                style="font-color: white"
                label="End date"
                prepend-icon="mdi-calendar"
                readonly
                v-on="on"
              />
            </template>
            <v-date-picker v-model="endDate" scrollable />
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="green" @click="updateEndDate"> Modify </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <br />
  </v-container>
</template>

<script>
import config from "@/config";
import axios from "axios";
import store from "../store";
import checkToken from "../store/checkToken";
export default {
  data: () => ({
    // Couleur
    red: config.colors.gricad_red_dark1,
    card_bg_dark: config.colors.card_bg_dark,
    card_bg_light: config.colors.card_bg_light,
    barchart_color: config.colors.gricad_blue_dark3,
    blue: config.colors.gricad_oceanblue,
    // Date du jour
    today: new Date().toISOString().substr(0, 10),
    // Initialisation des v-model
    StatusSearch: "",
    StatusList: ["actives", "pending", "rejected", "expired"],
    projectSearch: "",
    memberSearch: "",
    adminSearch: "",
    poleSearch: "",
    labSearch: "",
    reponse: "",
    endDate: "",
    reponseDialog: false,
    activate: false,
    enddateDialog: false,
    showPending: true,
    // Initialisation des variables gloables
    projectlist: [],
    project: {
      cn: "",
      description: "",
      status: "",
      laboratory: "",
      pole: "",
      beginDate: "",
      endDate: "",
      reviewDate: "",
      list_admins: [],
      list_members: [],
      scientificReview: "",
      technicalReview: "",
      projectType: "",
    },
    nb_active: null,   
    nb_pending: null,  
    nb_rejected: null,  
    nb_expired: null,  
  }),
  created() {
    axios.get("/getall-pendingProjects").then((response) => {
      this.projectlist = response.data;
    });
    // TODO Informations resume sur les projets
      axios.get("/get-nbActivesProjects").then((response) => {
        this.nb_active = response.data;
      });
      axios.get("/get-nbPendingProjects").then((response) => {
        this.nb_pending = response.data;
      });
      axios.get("/get-nbRejectedProjects").then((response) => {
        this.nb_rejected = response.data;
      });
      axios.get("/get-nbExpiredProjects").then((response) => {
        this.nb_expired = response.data;
      });
  },
  methods: {
    copyText(param_text) {
      navigator.clipboard.writeText(param_text);
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    dismiss() {
      this.reponse = "";
      this.reponseDialog = false;
    },
    style_listProject() {
      return {
        height: `${this.$store.state.windowH * (3 / 4)}px`,
      };
    },
    style_table_projectInfos() {
      return {
        "min-width": "100px",
        "max-width": "200px",
        "background-color": "green",
      };
    },

    activateProject() {
      axios
        .get("/project/" + this.project.cn + "/activate-project")
        .then((response) => {
          if (response.data.includes("[SUCCESS]")) {
            this.project.status = "Active";
            this.activate = false;
            this.reponse = response.data;
            this.reponseDialog = true;
            if (this.projectlist.includes(this.project.cn)) {
              this.projectlist.splice(
                this.projectlist.indexOf(this.projectlist.cn),
                1
              );
            }
          }
          this.reponse = response.data;
          this.reponseDialog = true;
        });
    },

    getProject(project) {
      var token = $cookies.get("token");
      var validToken = checkToken(token);
      if (!validToken) {
        this.$router.push("/login");
      } else {
        this.activate = false;
        axios.get("/get-project/" + project).then((response) => {
          var projectLocal = response.data;
          var projectCN = projectLocal.cn[0];
          this.project.cn = projectLocal.cn[0];
          this.project.description = projectLocal.description[0];
          if(projectLocal.projectType) {
            this.project.projectType = projectLocal.projectType[0]
          }
          else {
             this.project.projectType = "1"
          }
          this.project.beginDate = this.formatDate(
            projectLocal.creationDate[0] * 1000
          );
          this.project.endDate = this.formatDate(
            projectLocal.validityDate[0] * 1000
          );
          if (projectLocal.checkDate) {
            this.project.reviewDate = this.formatDate(
              projectLocal.checkDate[0] * 1000
            );
          }
          if (projectLocal.adminUid) {
            this.project.list_admins = projectLocal.adminUid;
          }
          if (projectLocal.memberUid) {
            this.project.list_members = projectLocal.memberUid;
          }
          axios
            .get("/project/" + projectCN + "/get-projectStatus")
            .then((response) => {
              this.project.status = response.data;
              this.project.technicalReview = "";
              this.project.scientificReview = "";
              if (projectLocal.perseusData) {
                var perseusData = JSON.parse(projectLocal.perseusData[0]);
                if (perseusData.scientific_review === "true") {
                  this.project.scientificReview = "true";
                } else {
                  this.project.scientificReview = "";
                }
                if (perseusData.technical_review === "true") {
                  this.project.technicalReview = "true";
                } else {
                  this.project.technicalReview = "";
                }
              }
              if (
                this.project.status === "Pending" &&
                this.project.scientificReview &&
                this.project.technicalReview
              ) {
                this.activate = true;
              }
            });
          axios.get("/project/" + projectCN + "/get-lab").then((response) => {
            var localLabo = response.data;
            this.project.laboratory = localLabo;
            axios.get("/get-poleToLab/" + localLabo).then((response) => {
              this.project.pole = response.data;
            });
          });
        });
      }
    },

    updateEndDate() {
      var token = $cookies.get("token");
      var validToken = checkToken(token);
      if (!validToken) {
        this.$router.push("/login");
      } else {
        var date = this.endDate.split("-");
        date = String(date[2] + "-" + date[1] + "-" + date[0]);
        axios
          .get("/project/" + this.project.cn + "/set-validityDate/" + date)
          .then((response) => {
            this.endDate = "";
            this.enddateDialog = false;
            this.reponse = response.data;
            this.reponseDialog = true;
            this.project.endDate = date.replace("-", "/").replace("-", "/");
          });
      }
    },

    search() {
      var token = $cookies.get("token");
      var validToken = checkToken(token);
      if (!validToken) {
        this.$router.push("/login");
      } else {
        this.projectlist = [];
        this.showPending = false;
        // Filtre par uid de projet (filtre prioritaire)
        if (this.projectSearch) {
          axios
            .get("/search-project/" + this.projectSearch)
            .then((response) => {
              if (String(response.data).includes("ERROR")) {
                this.reponse = response.data;
                this.reponseDialog = true;
              } else {
                if (response.data) {
                  if (this.projectlist.length) {
                    this.projectlist = this.projectlist.filter((x) =>
                      response.data.includes(x)
                    );
                  } else {
                    this.projectlist = response.data;
                  }
                }
              }
            });
        } else {
          // Filtre par status
          if (this.StatusSearch) {
            axios
              .get("/getall-" + this.StatusSearch + "Projects")
              .then((response) => {
                if (String(response.data).includes("ERROR")) {
                  this.reponse = response.data;
                  this.reponseDialog = true;
                } else {
                  // this.projectlist = response.data
                  if (response.data) {
                    if (this.projectlist.length) {
                      this.projectlist = this.projectlist.filter((x) =>
                        response.data.includes(x)
                      );
                    } else {
                      this.projectlist = response.data;
                    }
                  }
                }
              });
          }
          // Filtre par pole
          if (this.poleSearch) {
            axios
              .get("/getall-projectByPole/" + this.poleSearch)
              .then((response) => {
                if (String(response.data).includes("ERROR")) {
                  this.reponse = response.data;
                  this.reponseDialog = true;
                } else {
                  // this.projectlist = response.data
                  if (response.data) {
                    if (this.projectlist.length) {
                      this.projectlist = this.projectlist.filter((x) =>
                        response.data.includes(x)
                      );
                    } else {
                      this.projectlist = response.data;
                    }
                  }
                }
              });
          }
          // Filtre par laboratoire
          if (this.labSearch) {
            axios
              .get("/getall-projectByLab/" + this.labSearch)
              .then((response) => {
                if (String(response.data).includes("ERROR")) {
                  this.reponse = response.data;
                  this.reponseDialog = true;
                } else {
                  // this.projectlist = response.data
                  if (response.data) {
                    if (this.projectlist.length) {
                      this.projectlist = this.projectlist.filter((x) =>
                        response.data.includes(x)
                      );
                    } else {
                      this.projectlist = response.data;
                    }
                  }
                }
              });
          }
          // Filtre par Member uid
          if (this.memberSearch) {
            axios
              .get("/username/" + this.memberSearch + "/getall-projects4user")
              .then((response) => {
                if (String(response.data).includes("ERROR")) {
                  this.reponse = response.data;
                  this.reponseDialog = true;
                } else {
                  // this.projectlist = response.data
                  if (response.data) {
                    if (this.projectlist.length) {
                      this.projectlist = this.projectlist.filter((x) =>
                        response.data.includes(x)
                      );
                    } else {
                      this.projectlist = response.data;
                    }
                  }
                }
              });
          }
          // Filtre par Admin uid
          if (this.adminSearch) {
            axios
              .get(
                "/username/" + this.adminSearch + "/getall-projects4userAdmin"
              )
              .then((response) => {
                if (String(response.data).includes("ERROR")) {
                  this.reponse = response.data;
                  this.reponseDialog = true;
                } else {
                  // this.projectlist = response.data
                  if (response.data) {
                    if (this.projectlist.length) {
                      this.projectlist = this.projectlist.filter((x) =>
                        response.data.includes(x)
                      );
                    } else {
                      this.projectlist = response.data;
                    }
                  }
                }
              });
          }
          // Filtres vides
          if (
            this.poleSearch === "" &&
            this.projectSearch === "" &&
            this.labSearch === "" &&
            this.memberSearch === "" &&
            this.adminSearch === "" &&
            (this.StatusSearch === "" || this.StatusSearch == null)
          ) {
            axios.get("/getall-projects").then((response) => {
              if (String(response.data).includes("ERROR")) {
                this.reponse = response.data;
                this.reponseDialog = true;
              } else {
                this.projectlist = response.data;
              }
            });
          }
        }
      }
    },
    formatDate(date) {
      var d = new Date(date);
      var month = "" + (d.getMonth() + 1);
      var day = "" + d.getDate();
      var year = d.getFullYear();
      if (month.length < 2) {
        month = "0" + month;
      }
      if (day.length < 2) {
        day = "0" + day;
      }
      return [day, month, year].join("/");
    },
  },
  beforeRouteEnter(to, from, next) {
    var admin = store.state.admin;
    var reviewer = store.state.reviewer;
    if (!(admin || reviewer)) {
      next("/");
    } else {
      next();
    }
  },
};
</script>

<style lang="scss">
.text-muted {
  opacity: 0.7;
}
</style>
